import { get, map, merge } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import PayrollFileConfigurationAPI from '../../../api/payroll-file-configurations';
import { CustomTable } from '../../../components';
import usePaginatedFiltersQuery from '../../../hooks/queries/usePaginatedFiltersQuery';
import useTableExpandedRows from '../../../hooks/useTableExpandedRows';
import PayrolllExpandedRow from './PayrolllExpandedRow';

const PayrollFileConfigurationSection = props => {
  const { t, currentCompany } = props;

  const { expandedRowKeys, toggleExpandedRow } = useTableExpandedRows();

  const setExpandableOnDocuments = useCallback(data => {
    return merge({}, data, {
      documents: map(get(data, 'documents'), document => merge({}, document, { expandable: true })),
    });
  }, []);

  const {
    query: payrollFileConfigurationQuery,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  } = usePaginatedFiltersQuery({
    enabled: !!currentCompany?._id,
    select: setExpandableOnDocuments,
    queryKey: ['fetchPayrollFileConfiguration', currentCompany?._id],
    queryFn: () =>
      new PayrollFileConfigurationAPI().fetchPayrollFileConfiguration(
        currentCompany._id,
        paginationConfig.current,
        paginationConfig.pageSize,
      ),
  });

  const COLUMNS = useMemo(
    () => [
      {
        fixed: 'left',
        dataIndex: 'name',
        title: t('fileName'),
      },
    ],
    [t],
  );

  return (
    <section>
      <CustomTable
        withExpandableRows
        showSearchInput={false}
        expandedRowKeys={expandedRowKeys}
        loading={payrollFileConfigurationQuery.isFetching}
        onExpand={(expanded, record) => toggleExpandedRow(record)}
        columns={COLUMNS}
        dataSource={get(payrollFileConfigurationQuery.data, 'documents', [])}
        pagination={{
          pageSize: paginationConfig.pageSize,
          total: paginationConfig.total,
          current: paginationConfig.current,
          onShowSizeChange: handlePageSizeChange,
        }}
        onChange={({ current }, filters, sorters) => {
          handlePageChange(current);
          // handleTableSort(sorters?.columnKey, sorters?.order);
        }}
        expandedRowRender={rowData => <PayrolllExpandedRow t={t} {...rowData} />}
        expandedRowStyle={{ padding: 20, background: '#eeeff19e' }}
        scroll={{ x: true }}
      />
    </section>
  );
};

export default PayrollFileConfigurationSection;
